import React from "react"
import classes from "./Rhombus.module.css"

export interface RhombusProps {
  size: number
  sizeSm?: number
  color: string
}

const Rhombus = ({ size, sizeSm, color }: RhombusProps) => {
  return (
    <div
      className={`transform rotate-45 ${classes.root}`}
      style={{
        ["--color" as any]: color,
        ["--size" as any]: `${size}px`,
        ["--size-sm" as any]: `${sizeSm ?? size}px`,
      }}
    ></div>
  )
}

export default Rhombus
