import classNames from "classnames"
import React from "react"
import {
  RichTextProps,
  TwRichTextField,
} from "../../../root/punks/core/fields/RichTextField"
import classes from "./NiceTitle.module.css"

export interface NiceTitleProps extends RichTextProps {
  revert?: boolean
  rootComponent?: string
}

const NiceTitle = (props: NiceTitleProps) => {
  const { className, rootComponent, revert, ...other } = props

  console.log("NiceTitle", props)
  return (
    <TwRichTextField
      rootComponent={rootComponent}
      component="p"
      className={classNames(revert ? classes.revert : classes.root, className)}
      {...other}
    />
  )
}

export default NiceTitle

NiceTitle.defaultProps = {
  variant: "h5",
}
