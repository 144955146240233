import React from "react"
import ProductGridItem from "../ProductGridItem"
import { CtaData } from "../../../types"
import { CustomContainer } from "../../../spacings"
import CustomButton from "../../../atoms/Button"
import Link from "next/link"
import classNames from "classnames"
import { BsArrowRight } from "react-icons/bs"
import classes from "./ProductCarousel.module.css"
import Typography from "../../../atoms/Typography"
import ProductSwiperCarousel from "../ProductSwiperCarousel"
import { PartialGridItemProduct } from "../ProductGridItem/types"

export type CtaType = "button" | "slide"

export interface ProductCarouselProps {
  title: string
  subtitle?: string
  products: PartialGridItemProduct[]
  cta?: CtaData
  ctaType?: CtaType
  maxSlides?: number
}
const ProductCarousel = ({
  title,
  subtitle,
  products,
  cta,
  ctaType,
  maxSlides,
}: ProductCarouselProps) => {
  if (!products || products.length === 0) {
    return <></>
  }

  let productItems = products.map(
    (product: PartialGridItemProduct, index: number) => (
      <ProductGridItem key={index} product={product} prefetch={false} />
    )
  )

  const hasSlideCta = cta && ctaType === "slide"
  const maxProductItems = maxSlides
    ? hasSlideCta
      ? maxSlides - 1
      : maxSlides
    : undefined

  if (maxProductItems) {
    productItems = productItems.slice(0, maxProductItems)
  }

  if (cta && hasSlideCta) {
    productItems.push(
      <div className={classNames(classes.carouselItem, classes.slideCta)}>
        <Link href={cta.url} passHref>
          <a>
            <CustomButton variant="text" className={classes.slideCtaButton}>
              {cta.label}
            </CustomButton>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      <CustomContainer className="sm:hidden">
        <h2 className={classes.title}>{title}</h2>
        {subtitle && <h5 className={classes.subtitle}>{subtitle}</h5>}
      </CustomContainer>
      <div className="items-center justify-between hidden sm:flex">
        <Typography
          variant="h4"
          color="secondary"
          textClassName={classes.titleDesktop}
          component="p"
        >
          {title}
        </Typography>
        {cta && (
          <Link href={cta.url} passHref>
            <a className={classes.deskLink}>
              <Typography
                color="primary"
                weight="bold"
                variant="subtitle2"
                component="p"
                textClassName={classes.ctaLabel}
              >
                {cta.label}
                <BsArrowRight className={classes.deskLinkArrow} />
              </Typography>
            </a>
          </Link>
        )}
      </div>
      <ProductSwiperCarousel
        styles={{
          backgroundColor: "#fff",
          backgroundFilled: false,
        }}
        variant="default"
        productItems={productItems}
        className={classes.carouselRoot}
      />

      {cta && ctaType === "button" && (
        <div className={classes.cta}>
          <Link href={cta.url}>
            <div>
              <CustomButton size="small">{cta.label}</CustomButton>
            </div>
          </Link>
        </div>
      )}
    </div>
  )
}

ProductCarousel.defaultProps = {
  ctaType: "slide",
}

export default ProductCarousel
