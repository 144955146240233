import { RichText } from "@punks/core"
import classNames from "classnames"
import Link from "next/link"
import React from "react"
import Rhombus from "../../../atoms/Rhombus"
import Typography from "../../../atoms/Typography"
import NiceTitle from "../../../molecules/NiceTitle"
import { CtaData } from "../../../types"
import classes from "./ProductCarouselText.module.css"

interface Props {
  textColor?: string
  claim: RichText
  cta?: CtaData
  inline?: boolean
  className?: string
  prefetch?: boolean
}

const ProductCarouselText = ({
  textColor,
  claim,
  cta,
  inline,
  className,
  prefetch,
}: Props) => {
  return (
    <div
      className={classNames(
        "flex py-6 pr-6 justify-between",
        {
          "flex-row sm:flex-col": inline,
          "flex-col": !inline,
        },
        className
      )}
    >
      <div>
        {textColor && <Rhombus size={8} sizeSm={14} color={textColor} />}
        <NiceTitle
          value={claim}
          colorValue={textColor}
          className="mt-3"
          variant="h5"
          rootComponent="h2"
        />
      </div>
      {cta && (
        <div
          className={classNames("flex items-end flex-1 sm:flex-grow-0", {
            "justify-end sm:justify-start": inline,
          })}
        >
          <Link href={cta.url} passHref prefetch={prefetch}>
            <a>
              <Typography variant="body1" textClassName={classes.cta}>
                {`${cta.label} →`}
              </Typography>
            </a>
          </Link>
        </div>
      )}
    </div>
  )
}

export default ProductCarouselText
