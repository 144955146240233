import React from "react"
import ProductGridItem from "../ProductGridItem"
import { CtaData } from "../../../types"
import { CustomContainer } from "../../../spacings"
import classNames from "classnames"
import classes from "./ProductCarouselWall.module.css"
import { ImageContent, RichText } from "@punks/core"
import Rectangle from "../../../shapes/Rectangle"
import { SanityNextImg } from "../../../../cms/components"
import ProductCarouselText from "./ProductCarouselText"
import ConditionalLink from "../../../utilities/ConditionalLink"
import ProductSwiperCarousel from "../ProductSwiperCarousel"
import { PartialGridItemProduct } from "../ProductGridItem/types"

type ComponentVariant = "default" | "inline"

export type CtaType = "button" | "slide"

export interface ProductCarouselStyles {
  backgroundFilled: boolean
  backgroundColor: string
  backgroundColorContrast?: string
  textColor?: string
  bannerRatio?: number
  bannerRatioMobile?: number
}

export interface ProductCarouselWallProps {
  title: string
  subtitle?: string
  claim?: RichText
  banner?: ImageContent
  bannerMobile?: ImageContent
  bannerSeoTitle?: string
  products: PartialGridItemProduct[]
  cta?: CtaData
  ctaType?: CtaType
  maxSlides?: number
  styles: ProductCarouselStyles
}

const ProductCarouselWall = ({
  claim,
  banner,
  bannerMobile,
  bannerSeoTitle,
  products,
  cta,
  maxSlides,
  styles,
}: ProductCarouselWallProps) => {
  if (!products || products.length === 0) {
    return <></>
  }

  let productItems = products.map(
    (product: PartialGridItemProduct, index: number) => (
      <ProductGridItem
        key={index}
        product={product}
        prefetch={false}
        className={`${classes.productGridItem} gridItem`}
        lineXs
        textLeftXs
        verticalPriceXs
      />
    )
  )

  if (maxSlides) {
    productItems = productItems.slice(0, maxSlides)
  }

  const variant: ComponentVariant = banner ? "default" : "inline"

  return (
    <div
      className={classNames(
        classes.root,
        variant === "default" ? classes.rootDefault : classes.rootInline
      )}
      style={{
        ["--bg-color" as any]: styles.backgroundFilled
          ? styles?.backgroundColor
          : undefined,
        ["--bg-color-contrast" as any]: styles?.backgroundColorContrast,
        ["--carousel-bg-color" as any]: styles?.backgroundColor,
        ["--text-color" as any]: styles?.textColor,
      }}
    >
      <CustomContainer mobileNoGutters className={classes.container}>
        <ProductCarouselText
          textColor={styles?.textColor}
          claim={claim}
          cta={cta}
          inline={variant === "inline"}
          className={`mx-4 carouselMd:mx-0 ${
            variant === "inline"
              ? classes.textColInline
              : classes.textColDefault
          } `}
          prefetch={false}
        />
        {banner && (
          <div className="flex items-center">
            <Rectangle
              ratio={styles.bannerRatio ?? 26.6}
              className={`hidden sm:block ${classes.banner}`}
            >
              <ConditionalLink link={cta?.url} prefetch={false}>
                <SanityNextImg
                  asset={banner}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  width={1260}
                  alt={bannerSeoTitle}
                  blurUp
                />
              </ConditionalLink>
            </Rectangle>
          </div>
        )}
        {(bannerMobile || banner) && (
          <div
            className={classNames(classes.bannerMobile, {
              [classes.bannerMobileFilled]: styles.backgroundFilled,
            })}
          >
            <Rectangle ratio={styles.bannerRatioMobile ?? 80}>
              <ConditionalLink link={cta?.url} prefetch={false}>
                <SanityNextImg
                  noBox
                  asset={bannerMobile ?? banner}
                  objectFit="cover"
                  layout="fill"
                  objectPosition="center"
                  width={600}
                  alt={bannerSeoTitle}
                  blurUp
                />
              </ConditionalLink>
            </Rectangle>
          </div>
        )}

        <ProductSwiperCarousel
          styles={styles}
          variant={variant}
          productItems={productItems}
        />
      </CustomContainer>
    </div>
  )
}

ProductCarouselWall.defaultProps = {
  ctaType: "slide",
}

export default ProductCarouselWall
