import classNames from "classnames"
import React, { useCallback } from "react"
import SwiperCore from "swiper"
import SwiperCarousel from "../../../molecules/SwiperCarousel"
import classes from "./ProductSwiperCarousel.module.css"

type CarouselVariant = "default" | "inline"

interface CarouselStyles {
  backgroundFilled: boolean
  backgroundColor: string
  backgroundColorContrast?: string
  textColor?: string
  bannerRatio?: number
  bannerRatioMobile?: number
}

interface Props {
  variant: CarouselVariant
  styles: CarouselStyles
  productItems: React.ReactNode[]
  className?: string
  maxSlidesPerView?: number
}

const ProductSwiperCarousel = ({
  variant,
  styles,
  productItems,
  className,
  maxSlidesPerView,
}: Props) => {
  const [carousel, setCarousel] = React.useState<SwiperCore | undefined>()
  const [isEnd, setIsEnd] = React.useState(false)
  const [isStart, setIsStart] = React.useState(true)
  const [hasLessSlides, setHasLessSlides] = React.useState(false)
  const [arrowAnchorLeft, setArrowAnchorLeft] = React.useState<number>()
  const [cardSeparatorY, setCardSeparatorY] = React.useState<number>()
  const [cardHeight, setCardHeight] = React.useState<number>()

  const updateArrowAnchorLeft = (carousel: SwiperCore) =>
    setArrowAnchorLeft(carousel.slides?.[1]?.getBoundingClientRect().right)

  const updateCardHeight = (carousel: SwiperCore) => {
    setCardHeight(carousel.el.getBoundingClientRect().height)
  }

  const updateCardSeparatorY = (carousel: SwiperCore) => {
    if (!carousel.el) {
      return
    }
    const yTop = carousel.el.getBoundingClientRect().y
    const yBottom =
      carousel.el
        .getElementsByClassName("card-separator")?.[0]
        ?.getBoundingClientRect().y ?? 0
    const height = yBottom - yTop
    setCardSeparatorY(height)
  }

  const checkSlidesCount = (carousel: SwiperCore) => {
    if (!carousel.params.slidesPerView) {
      return
    }
    setHasLessSlides(
      carousel.slides.length <
        Math.floor(carousel.params.slidesPerView as number)
    )
  }

  React.useEffect(() => {
    if (carousel && !carousel.destroyed) {
      checkSlidesCount(carousel)

      setIsEnd(carousel.isEnd)

      carousel.on("slideChange", (x) => {
        setIsEnd(x.isEnd)
        setIsStart(x.isBeginning)
      })
      updateArrowAnchorLeft(carousel)
      updateCardSeparatorY(carousel)
      updateCardHeight(carousel)

      carousel.on("resize", (x) => {
        if (x.isBeginning) {
          updateArrowAnchorLeft(x)
        }
        updateCardSeparatorY(x)
        updateCardHeight(x)
      })
    }
  }, [carousel])

  const carouselTopH = cardSeparatorY ? `calc(${cardSeparatorY}px + 1rem)` : 0

  const normalizeSlidesPerView = useCallback(
    (slidesPerView: number) => {
      return maxSlidesPerView && slidesPerView > maxSlidesPerView
        ? maxSlidesPerView
        : slidesPerView
    },
    [maxSlidesPerView]
  )

  return (
    <div
      className={classNames(
        "relative",
        {
          [classes.carouselInline]: variant === "inline",
          [classes.carouselDefault]: variant === "default",
          [classes.carouselBgFill]: styles.backgroundFilled,
        },
        className
      )}
      style={{
        ["--bg-color" as any]: styles.backgroundFilled
          ? styles?.backgroundColor
          : undefined,
        ["--bg-color-contrast" as any]: styles?.backgroundColorContrast,
        ["--carousel-bg-color" as any]: styles?.backgroundColor,
        ["--text-color" as any]: styles?.textColor,
        ["--card-separator-y" as any]: cardSeparatorY
          ? `${cardSeparatorY}px`
          : undefined,
      }}
    >
      <div
        className={classes.wallBack}
        style={{
          backgroundColor: styles?.backgroundColor,
          zIndex: -1,
          opacity: cardSeparatorY ? 1 : 0,
        }}
      ></div>
      <div
        className={classNames(
          classes.arrowBoxPrev,
          isStart ? classes.arrowStart : ""
        )}
        style={{
          height: carouselTopH,
          display: carouselTopH ? undefined : "none",
        }}
      >
        <div
          className={`${classes.arrow} ${
            variant === "inline" ? classes.arrowInline : ""
          } noselect`}
          onClick={() => carousel?.slidePrev()}
        >
          ←
        </div>
      </div>
      <div
        className={classNames(
          classes.arrowMobilePrev,
          styles.backgroundFilled ? classes.arrowMobilePrevFilled : "",
          isStart ? classes.arrowStart : ""
        )}
      >
        <div
          className={`${classes.arrow} noselect`}
          onClick={() => carousel?.slidePrev()}
        >
          ←
        </div>
      </div>
      <SwiperCarousel
        className={classNames(classes.swiperCarousel, {
          [classes.swiperCarouselCenter]: hasLessSlides,
        })}
        loop={false}
        dots={false}
        slidesToShowXs={normalizeSlidesPerView(2.35)}
        slidesToShowSm={normalizeSlidesPerView(3)}
        slidesToShowMd={normalizeSlidesPerView(variant === "inline" ? 4 : 5)}
        slidesPerGroupMd={normalizeSlidesPerView(variant === "inline" ? 4 : 5)}
        slidesPerGroupSm={normalizeSlidesPerView(2)}
        slidesPerGroupXs={normalizeSlidesPerView(2)}
        centerPadding={10}
        centerPaddingSm={18}
        slidesOffsetBefore={16}
        slidesOffsetBeforeSm={0}
        slidesOffsetBeforeMd={0}
        onSwiper={setCarousel}
        h100
        forceCarouselForSingleItem
      >
        {productItems.map((item, index) => (
          <div key={index} className={classes.carouselItem}>
            <div
              className={classes.slideBg}
              style={{ height: cardHeight }}
            ></div>
            {item}
          </div>
        ))}
      </SwiperCarousel>
      <div
        className={classNames(
          classes.arrowBoxNext,
          isEnd ? classes.arrowEnd : ""
        )}
        style={{
          height: carouselTopH,
          display: carouselTopH ? undefined : "none",
        }}
      >
        <div
          className={`${classes.arrow} ${
            variant === "inline" ? classes.arrowInline : ""
          } noselect`}
          onClick={() => carousel?.slideNext()}
        >
          →
        </div>
      </div>
      {arrowAnchorLeft && (
        <div
          className={classNames(
            classes.arrowMobileNext,
            styles.backgroundFilled ? classes.arrowMobileNextFilled : "",
            isEnd ? classes.arrowEnd : ""
          )}
          style={{
            left: arrowAnchorLeft,
            opacity: arrowAnchorLeft ? 1 : 0,
          }}
        >
          <div
            className={`${classes.arrow} noselect`}
            onClick={() => carousel?.slideNext()}
          >
            →
          </div>
        </div>
      )}
    </div>
  )
}

export default ProductSwiperCarousel
